
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Generator from "@/components/reusable/Generator.vue";
import Contents from "@/components/reusable/Contents.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "own-building-step-2",
  components: {
    Field,
    ErrorMessage,
    ContentsBuildingModal,
    ScheduleInfoModal,
    Contents,
    Generator,
  },
  props: {
    personalEffectsRate: Number,
    cashValueRate: Number,
    generatorRate: Number,
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    publicId: String,
    underwriterId: String,
  },
  data() {
    return {
      schedule: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      displayContent: false,
      totalPremium: 0.0 as number,
      coopPublicId: "",
      policySummary: {
        phoneDetails: {},
        laptopOrTabletDetails: {},
        jewelryDetails: {},
        cameraDetails: {},
        musicalInstrumentDetails: {},
        inverterDetails: {},
      },
    };
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 5) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          underwriter: this.underwriterId,
          package: this.publicId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    displayContents() {
      this.displayContent = this.displayContent == true ? false : true;

      if (this.displayContent == false) {
        setTimeout(() => {
          // Update total premium
          this.totalPremium = variables.totalPremiumValue();
        }, 200);
      }
    },
    scheduleSelected(event) {
      this.schedule = event.target.files[0];
    },
  },
});
