
import { defineComponent } from "vue";
import Phone from "@/components/reusable/Phone.vue";
import Laptop from "@/components/reusable/Laptop.vue";
import Jewelry from "@/components/reusable/Jewelry.vue";
import Camera from "@/components/reusable/Camera.vue";
import MusicalInstrument from "@/components/reusable/MusicalInstrument.vue";
import Inverter from "@/components/reusable/Inverter.vue";

export default defineComponent({
  name: "house-insurance-default-contents-component",
  components: { Phone, Laptop, Jewelry, Camera, MusicalInstrument, Inverter },
  props: {
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    publicId: String,
    underwriterId: String,
  },
});
