
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "rented-building-step-1",
  components: {
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
  },
  props: {
    alternativeAccommodationValueRate: Number,
    publicId: String,
    underwriterId: String,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      buildingType: "",
      alternativeAccommodation: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      zipCode: "",
      buildingDescription: "",
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 6) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          underwriter: this.underwriterId,
          package: this.publicId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
  },
});
